import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { ProductPriceType } from 'types/price';
import { twMergeCustom } from 'utils/twMerge';

type ProductPriceProps = {
    productPrice: ProductPriceType;
    quantity?: number;
    withUnitsInfo?: boolean;
    unit?: string;
};

const TEST_IDENTIFIER = 'blocks-product-price';

export const ProductPrice: FC<ProductPriceProps> = ({ productPrice, quantity, className, withUnitsInfo, unit }) => {
    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();

    return (
        <div className={twMergeCustom('flex flex-row flex-wrap items-center', className)}>
            <div className="mr-2 text-lg leading-5 text-primary" data-testid={TEST_IDENTIFIER}>
                {productPrice.isPriceFrom && t('From') + '\u00A0'}
                {quantity ? formatPrice(productPrice.priceWithVat * quantity) : formatPrice(productPrice.priceWithVat)}
            </div>
            {withUnitsInfo &&
                quantity &&
                `${t('for {{ amount }} {{ unit }}', { amount: quantity, unit: unit })} ${t('without VAT')}`}
        </div>
    );
};
